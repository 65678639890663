import React from "react"
import CategoryProducts from "../components/CategoryProducts/CategoryProducts"

import Layout from "../components/Layout/Layout"
import ProductHero from "../components/ProductHero/ProductHero"
import SEO from "../components/SEO/SEO"
import productsJSON from "../data/products.json"
import categoriesJSON from "../data/categories.json"

const IndexPage: React.FC = () => {
  const products = productsJSON.products
  const categories = categoriesJSON.categories
  const mainProduct = products.find(({ home_main }) => home_main)

  return (
    <>
      <SEO title="Home" />
      <Layout>
        {mainProduct ? <ProductHero product={mainProduct} /> : null}
        <div>
          {categories.map(({ id }) => (
            <CategoryProducts categoryId={id} key={id} />
          ))}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
